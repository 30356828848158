@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Poppins";
}



/* Custom scrollbar styles to hide only the scrollbar thumb */
.scrollbar-hidden {

  scrollbar-width: none !important;


}

.heading-color{
  color:#001b33;
}
.bullet-points {
  width: full;
  display: flex;
  /* justify-content: center;
  align-items: center; */

  gap: 3px;
}

.bullet-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fb8c00;

}

.active-bullet {
  width: 30px;
  border-radius: 40%;
}
.card-slider{
  width: auto;
  /* background-color: #fff; Ensure the background is white */
  /* margin: 10px; Add some margin if necessary for better shadow visibility */
  box-shadow: 9px 9px 50px rgba(189, 189, 189, 0.35);
  /* border-radius: 8px; */
}


.dot{
 
    width: 4;
    height: 4;
    border-radius: 5;
    background-color: "black";
    
}

.terms-card{
 
    
    /* background-color: #fff; Ensure the background is white */
    /* margin: 10px; Add some margin if necessary for better shadow visibility */
    box-shadow: 9px 9px 50px rgba(189, 189, 189, 0.35);
    /* border-radius: 8px; */
  
}

@keyframes autoScroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.overflow-x-scroll {
  animation: autoScroll linear infinite;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}
